import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteBlog, getBlogList, getProfile } from "../store/apiSlice";
import { Pencil, Trash2 } from "lucide-react";
import Modal from "react-modal";
import FloatingInput from "../components/elements/FloatingInput";
import Table from "../components/elements/Table";
import Button from "../components/elements/Button";
import Title from "../components/elements/Title";
import Breadcrumb from "../components/elements/Breadcrumb";
import SearchDropdownFilter from "../components/elements/SearchDropdownFilter";
import { Link } from "react-router-dom";
import CustomModal from "../components/elements/Modal";
import DeleteModal from "../components/DeleteModal";

Modal.setAppElement("#root");

const Blogs = () => {
  document.title = "Blogs - Weddified Admin";
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const [id, setId] = useState("");
  const [blogs, setBlogs] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [load, setload] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [parentFilter, setparentFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setcategories] = useState(null);

  const viewModal = async (item) => {
    setIsOpen(true);
    setId(item);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const blogsDelete = async (id) => {
    try {
      await dispatch(deleteBlog(id)).unwrap();
      setIsOpen(false);
      Blogs();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Blogs = async () => {
    try {
      setload(true);
      setBlogs(null);
      const response = await dispatch(getBlogList()).unwrap();
      setBlogs(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Blogs();
  }, []);

  const applyFilter = () => {
    const filteredResult = categories?.filter((item) => {
      const userName = item?.name.toLowerCase();
      return (
        parentFilter === "" || userName.includes(parentFilter.toLowerCase())
      );
    });

    setFilteredData(filteredResult);
    settoggle(true);
  };

  const resetFilter = () => {
    setparentFilter("");
    setFilteredData(categories);
    settoggle(true);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <DeleteModal closeModal={closeModal} onClick={() => blogsDelete(id)} label="Blog" />
      </CustomModal>
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "Blogs" },
            { label: "All Blogs", active: true },
          ]}
        />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Blogs</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3 g-6">
            {/* <SearchDropdownFilter
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              toggleCheck={toggle}
            >
              <FloatingInput
                onChange={(e) => setparentFilter(e.target.value)}
                value={parentFilter}
                placeholder="Search Category"
              />
              <FloatingInput
                onChange={(e) => setparentFilter(e.target.value)}
                value={parentFilter}
                placeholder="Blog Category"
              />
            </SearchDropdownFilter> */}
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Link to={'/add-blog'} className="btn wf-btn wf-purple-btn py-2">
              Add Blog
            </Link>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Blogs: {blogs?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Blog Name</th>
                <th scope="col">Category</th>
                <th scope="col">Blog Category</th>
                <th scope="col">Excerpt</th>
                <th scope="col">Author</th>
                <th style={{ width: "100px" }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {load
                ? new Array(12).fill(null).map((_, index) => (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{ height: "40px" }}
                        className="w-100 shimmer"
                      ></div>
                    </td>
                  </tr>
                ))
                : blogs?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.title}</td>
                    <td>{item?.categoryId?.name}</td>
                    <td>{item?.blogCategoryId?.name}</td>
                    <td>{item?.excerpt}</td>
                    <td>{item?.createdBy?.name}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={`/edit-blog?id=${item?._id}`} className={`wf-action-icons`}><Pencil width="18" /></Link>
                        <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Blogs;
