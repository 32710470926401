import React, { useEffect, useState } from 'react'
import CustomModal from '../components/elements/Modal';
import DeleteModal from '../components/DeleteModal';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';
import Pagination from '../components/elements/Pagination';
import { ExternalLink, Pencil, Star, Trash2 } from 'lucide-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import SearchDropdownFilter from '../components/elements/SearchDropdownFilter';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSupplier, featureSupplier, getCategoryList, getCitiesList, getProfile, getSuppliersList, getUserList } from '../store/apiSlice';

const Suppliers = () => {
  const profile = useSelector(getProfile);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [categories, setcategories] = useState(null);
  const [cities, setcities] = useState([]);
  const [suppliers, setsuppliers] = useState(null);
  const [admins, setadmins] = useState(null);
  const [id, setId] = useState("");
  const [userDetail, setUserDetail] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);
  const [totalCount, settotalCount] = useState("");
  const [search, setsearch] = useState("");
  const [admin, setadmin] = useState("");
  const [category, setcategory] = useState("");
  const [city, setcity] = useState("");
  const [date, setdate] = useState("");
  const [country, setcountry] = useState("66596fcc9079984b6b40ad6e");

  function viewModal(item, type) {
    setIsOpen(true);
    if (type == "userDetail") {
      setUserDetail(item);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const storeDelete = async (id) => {
    try {
      await dispatch(deleteSupplier(id)).unwrap();
      setIsOpen(false);
      setsuppliers((prevCities) => prevCities?.filter(store => store?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getCategoryList("0")).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const getCity = async () => {
    try {
      setcities(null)
      const response = await dispatch(getCitiesList()).unwrap();
      setcities(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError, "error");
    }
  };

  

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const totalPages = Math.ceil(totalCount / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, totalCount);


  const SupplierList = async () => {
    try {
      setload(true);
      setsuppliers(null);
      const response = await dispatch(getSuppliersList({page: currentPage, search: search, admin: admin, category: category, city: city, date: date})).unwrap();
      setsuppliers(response?.data);
      settotalCount(response?.totalCount);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    SupplierList();
  }, [currentPage, search, admin, category, city, date]);

  const handleFeatureVendor = async (itemm,value) => {
    try {
      await dispatch(featureSupplier({ id: itemm._id, is_featured: value==1?0:1 })).unwrap();
      const items = suppliers.map((item) =>
        item._id === itemm._id ? { ...item, is_featured: value==1?0:1 } : item
      );
      setsuppliers(items);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [storeNameFilter, setstoreNameFilter] = useState('');
  const [selectcategory, setselectcategory] = useState('');
  const [selectcity, setselectcity] = useState('');
  const [dateFilter, setdateFilter] = useState('');
  const [selectedUsers, setselectedUsers] = useState('');

  const applyFilter = () => {
    setsearch(storeNameFilter)
    setadmin(selectedUsers)
    setcategory(selectcategory)
    setcity(selectcity)
    setdate(dateFilter)
    navigate(`${location.pathname}`);
    settoggle(false);
  };

  const resetFilter = () => {
    setselectcategory('');
    setselectcity('');
    setdateFilter('');
    setstoreNameFilter("")
    setselectedUsers('');
    setsearch('')
    setadmin('')
    setcity('')
    setcategory('')
    setdate('')
    settoggle(false);
  };

  useEffect(() => {   
    Categories();
    getCity();
  }, []);

  const handlePageChange = (page) => {
    navigate(`${location.pathname}?page=${page}`);
    setCurrentPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    if (location.search) {
      const queryParams = new URLSearchParams(location.search);
      const pageParam = queryParams.get('page');
      const parsedPage = parseInt(pageParam, 10) || 1;
      setCurrentPage(parsedPage);
    }
  }, [location.search]);

  return (
    <>

    <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
    {modalType == "delete" ? (
        <DeleteModal closeModal={closeModal} onClick={() => storeDelete(id)} label="Supplier" />
    ) : null}
    </CustomModal>

    <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
    <Breadcrumb items={[{ label: 'Menu',},{ label: 'Suppliers', active: true }]}/>
        <div className="row gy-3 mb-4 align-items-center mb-2">
        <div className="col-md-3 col-6 d-flex align-items-center gap-2 order-1">
            <Title>Suppliers</Title>
        </div>
        <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter}>
            <div class="form-floating wf-input mb-3">
                <input type="text" class="form-control" id="store-name-filter" placeholder="Store Name" onChange={(e) => setstoreNameFilter(e.target.value)} value={storeNameFilter}/>
                <label for="store-name-filter">Store Name</label>
                </div>
            <div class="dropdown wf-select-dropdown mb-3">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectcategory?.length > 0 ? selectcategory : "Category"}
                </a>
                <ul class="dropdown-menu">
                    {categories?.length > 0 && categories?.map((c, i) => (
                    <li key={i}><a onClick={() => setselectcategory(c?._id)} className="dropdown-item">
                        {c?.name}
                    </a></li>
                    ))}
                </ul>
                </div>

                <div class="dropdown wf-select-dropdown mb-3">
                <a class="dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {selectcity?.length > 0 ? cities?.find(item => item?._id == selectcity).name  : "City"}
                </a>
                <ul class="dropdown-menu">
                    {cities?.length > 0 && cities?.filter(item => item?.country_id === country)?.map((c, i) => (
                    <li key={i}><a onClick={() => setselectcity(c?._id)} className="dropdown-item">
                        {c?.name}
                    </a></li>
                    ))}
                </ul>
                </div>
                <div class="form-floating wf-input mb-3">
                    <input type="date" class="form-control" id="date-filter" placeholder="Date" onChange={(e) => setdateFilter(e.target.value)} value={dateFilter}/>
                    <label for="date-filter">Date</label>
                </div>

                <div class="row mb-3 sp-input-filter">
                <label for="inputname" class="col-sm-2 col-form-label font-size-20 pink-color dm-font">Author</label>
                <div class="col-sm-10 d-flex gap-2 flex-wrap">
                    {admins?.map((data, i) => (
                    <>
                        <input type="radio" class="btn-check" checked={selectedUsers.includes(data?._id)} onChange={() => setselectedUsers(data?._id)} id={`created-by-${data?._id}`} value={data?._id} />
                        <label class="btn wf-select-btn" for={`created-by-${data?._id}`}>{data?.name}</label>
                    </>
                    ))}

                </div>
                </div>
            </SearchDropdownFilter>
        </div>
        <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Link class="btn wf-btn wf-purple-btn px-3" to="/quick-add-vendor">Quick Add</Link>
            <Link class="btn wf-btn wf-purple-btn px-3" to="/add-supplier">Add Supplier</Link>
        </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
        <p className="font-size-20 pink-color">Suppliers : {totalCount}</p>
        <div class="table-responsive">
            <table class="table wf-table table-borderless table-striped">
            <thead>
                <tr>
                <th scope="col">S.No</th>
                <th scope="col">Store Name</th>
                <th scope="col">Category</th>
                <th scope="col">City</th>
                <th scope="col">Date</th>
                <th scope="col">Author</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                {load ? new Array(20).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '35px' }} className='w-100 shimmer'></div></td></tr>)) : suppliers?.map((item, i) => (
                <tr key={i}>
                    <td>{i + startIndex + 1}</td>
                    <td>{item?.store_name}</td>
                    <td>{item?.category?.name}</td>
                    <td>{item?.city?.name}</td>
                    <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                    <td>{item?.added_by?.name}</td>
                    <td>
                    <div className="d-flex align-items-center gap-2">
                        <div onClick={() => handleFeatureVendor(item,item?.is_featured)} className={`wf-action-icons`}>
                        {item?.is_featured == 1 ? (<Star fill="#804099" />) : (<Star  />)}
                        </div>
                        <a href={`https://www.shaditayari.pk/supplier-details/${item?.slug}`} target="_blank"  className={`wf-action-icons`}><ExternalLink width="18" /></a>
                        <Link to={`/edit-vendor/${item._id}`}  className={`wf-action-icons`}><Pencil width="18" /></Link>
                        <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
                        {/* {profile?.role == "admin"&&(<div class="form-check form-switch wf-switch">
                        <input checked={item?.approved == 1} onChange={(e) => Approve(e,item?._id)} class="form-check-input" type="checkbox" role="switch" id="approve-disapprove"/>
                        </div>)} */}
                    </div>
                    </td>
                </tr>))}
            </tbody>
            </table>
        </div>
        {totalCount > 50 &&
        <Pagination totalPages={totalPages} currentPage={currentPage} handlePageChange={handlePageChange} />
        }
        </div>
    </div>
    </>
  )
}

export default Suppliers