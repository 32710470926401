import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addQuestion, editQuestion, getCategoryList, getQuestionList, uploadAttachment } from '../store/apiSlice';
import Spinner from '../components/Spinner';
import { Trash2 } from 'lucide-react';
import Breadcrumb from '../components/elements/Breadcrumb';
import Title from '../components/elements/Title';
import FloatingInput from '../components/elements/FloatingInput';
import Button from '../components/elements/Button';
import DynamicSelect from '../components/elements/DynamicSelect';
import SelectButton from '../components/elements/SelectButton';

const AddQuestion = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  document.title = `${id ? "Edit" : "Add"} Question - Weddified Admin`

  const types = [
    { value: "checkBox", label: "Checkbox" },
    { value: "radio", label: "Radio" },
  ]

  const questionFors = [
    { value: "customer", label: "Customer" },
    { value: "supplier", label: "Supplier" },
    { value: "planner", label: "Planner" },
  ]

  const [questiondetail, setquestiondetail] = useState(null);
  const [categories, setcategories] = useState(null);


  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getCategoryList("1")).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const QuestionDetail = async () => {
    try {
      setquestiondetail(null);
      const response = await dispatch(getQuestionList()).unwrap();
      const findQuestion = response?.data?.find(item => item?._id === id)
      console.log(findQuestion);
      setquestiondetail(findQuestion);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (id) {
      QuestionDetail();
    }
  }, []);

  useEffect(() => {
    Categories();
  }, []);


  // const addandEditQuestion = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formattedOptions = options?.map(option => ({ name: option.name }));
  //     const formattedOptions2 = options?.map(option => ({ _id: option._id ? option._id : "", name: option.name }));
  //     setload(true)
  //     const formData = new FormData();
  //     if (id) formData.append("id", id)
  //     formData.append("questionCustomer", questionCustomer)
  //     formData.append("questionVendor", questionVendor)
  //     formData.append("headingText", headingText)
  //     formData.append("categoryId", category)
  //     formData.append("type", type)
  //     formData.append("roles", JSON.stringify(role))
  //     formData.append("options", JSON.stringify(id ? formattedOptions2 : formattedOptions))
  //     if (id) formData.append("previousOptions", JSON.stringify(prevoptions))
  //     options.forEach((opt) => {
  //       formData.append(`image`, opt.image)
  //     })
  //     if (id) {
  //       await dispatch(editQuestion({ id: id, bodyData: formData })).unwrap();
  //     } else {
  //       await dispatch(addQuestion(formData)).unwrap();
  //     }
  //     setload(false)
  //     navigate("/questions")
  //   } catch (rejectedValueOrSerializedError) {
  //     console.log(rejectedValueOrSerializedError);
  //     setload(false)
  //   }
  // };


  const [headingText, setheadingText] = useState('');
  const [questionCustomer, setquestionCustomer] = useState('');
  const [questionVendor, setquestionVendor] = useState('');
  const [category, setcategory] = useState('');
  const [type, settype] = useState('');
  const [questionFor, setquestionFor] = useState([]);
  const [options, setoptions] = useState([{ name: '', image: null }]);
  const [prevoptions, setprevoptions] = useState([]);
  const [load, setload] = useState(false);


  const handlePrevOptionChange = (index, field, value) => {
    const updatedOptions = [...prevoptions];
    updatedOptions[index][field] = value;
    setprevoptions(updatedOptions);
  };

  const handleOptionChange = (index, field, value) => {
    const updatedOptions = [...options];
    updatedOptions[index][field] = value;
    setoptions(updatedOptions);
  };

  const handleDeletePrevOption = (index) => {
    const updatedOptions = prevoptions.filter((_, i) => i !== index);
    setprevoptions(updatedOptions);
  };

  const handleDeleteOption = (index) => {
    const updatedOptions = options.filter((_, i) => i !== index);
    setoptions(updatedOptions);
  };

  const handleCheckboxChange = (value) => {
    setquestionFor(prev =>
      prev.includes(value)
        ? prev.filter(item => item !== value)
        : [...prev, value]
    );
  };

  const addNewOption = () => {
    setoptions(prev => [...prev, { name: '', image: null }]);
  };


  const addandEditQuestion = async () => {
    try {
      const uploadedOptions = await Promise.all(
        options.map(async (option) => {
          if (option.image && typeof option.image !== 'string') {
            const formData = new FormData();
            formData.append('image', option.image);

            const response = await dispatch(uploadAttachment(formData)).unwrap();
            return {
              name: option.name,
              icon: response?.data
            };
          }
          return option;
        })
      );

      const allOptions = [...prevoptions, ...uploadedOptions];

      const questionData = {
        id: id,
        categoryId: category,
        questionCustomer,
        questionVendor,
        headingText,
        type,
        options: allOptions,
        questionFor
      };


      if (id) {
        await dispatch(editQuestion(questionData)).unwrap();
      } else {
        await dispatch(addQuestion(questionData)).unwrap();
      }
      navigate('/questions');
    } catch (error) {
      console.error('Error creating question:', error);
    }
  };


  useEffect(() => {
    if (questiondetail) { // Assuming you have some way to know if editing
      setheadingText(questiondetail?.headingText || '');
      setquestionCustomer(questiondetail?.questionCustomer || '');
      setquestionVendor(questiondetail?.questionVendor || '');
      setcategory(questiondetail?.categoryId?._id || '');
      settype(questiondetail?.type || '');
      setquestionFor(questiondetail?.questionFor || []);
      setprevoptions(questiondetail?.options || []);
      setoptions([]); // Reset new options
    }
  }, [questiondetail]);

  return (
    <>
      {load && <Spinner />}
      <div className="wf-attributes-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'System Config', }, { label: 'Questions', link: "/questions" }, { label: `${id ? "Edit" : "Add"} Question`, active: true }]} />
        <Title bottomSpace={3}>{id ? "Edit" : "Add"} Question</Title>
        <div className="radius-20 white-bg wf-shadow p-3 mb-4">
          <div className="row gy-3 mb-3">
            <div className="col-md-12">
              <FloatingInput value={headingText} onChange={(e) => setheadingText(e.target.value)} placeholder="Question Heading" />
            </div>
            <div className="col-md-6">
              <FloatingInput value={questionCustomer} onChange={(e) => setquestionCustomer(e.target.value)} placeholder="Question Customer" />
            </div>
            <div className="col-md-6">
              <FloatingInput value={questionVendor} onChange={(e) => setquestionVendor(e.target.value)} placeholder="Question Supplier" />
            </div>
            <div className="col-md-6">
              <DynamicSelect options={categories} optionLabel='name' optionValue='_id' placeholder="Categories" onChange={(e) => setcategory(e.target.value)} value={category} />
            </div>
            <div className="col-md-6">
              <DynamicSelect options={types} placeholder="Type" onChange={(e) => settype(e.target.value)} value={type} />
            </div>
            <div className="col-md-12">
              <p class="font-size-20 pink-color dm-font mb-2">Question For</p>
              <div className="d-flex gap-2">
                {questionFors?.map((data, index) => (
                  <SelectButton key={index} value={data?.value} checked={questionFor?.includes(data?.value)} onChange={() => handleCheckboxChange(data?.value)} label={data?.label} id={data?.value} />
                ))}
              </div>
            </div>
          </div>

          <p class="font-size-20 pink-color dm-font mb-2">Answer Options</p>
          {prevoptions?.length > 0 && prevoptions.map((option, index) => (
            <div className="row mb-3" key={`prev-${index}`}>
              <div className="col-md-8">
                <FloatingInput id={`Answer-Option-${index + 1}`} value={option.name} onChange={(e) => handlePrevOptionChange(index, 'name', e.target.value)} placeholder={`Answer Option ${index + 1}`} />
              </div>
              <div className="col-md-3 col-11">
                <FloatingInput type="file" id={`Answer-Option-icon-${index + 1}`} onChange={(e) => handlePrevOptionChange(index, 'image', e.target.files[0])} placeholder={`Answer Option Icon ${index + 1}`} />
              </div>
              {index > 0 && (
                <div className="col-1 align-self-center trash-icon" onClick={() => handleDeletePrevOption(index)}>
                  <Trash2 className="pink-color cursor-pointer" />
                </div>
              )}
            </div>
          ))}

          {options?.length > 0 && options.map((option, index) => (
            <div className="row mb-3" key={`new-${index}`}>
              <div className="col-md-8">
                <FloatingInput id={`Answer-Option-${prevoptions?.length + index + 1}`} value={option.name} onChange={(e) => handleOptionChange(index, 'name', e.target.value)} placeholder={`Answer Option ${prevoptions?.length + index + 1}`} />
              </div>
              <div className="col-md-3 col-11">
                <FloatingInput type="file" id={`Answer-Option-icon-${prevoptions?.length + index + 1}`} onChange={(e) => handleOptionChange(index, 'image', e.target.files[0])} placeholder={`Answer Option Icon ${prevoptions?.length + index + 1}`} />
              </div>
              {index > 0 && (
                <div className="col-1 align-self-center trash-icon" onClick={() => handleDeleteOption(index)}>
                  <Trash2 className="pink-color cursor-pointer" />
                </div>
              )}
            </div>
          ))}

          {((options.length > 0 && options[0]?.name && options[0]?.image) ||
            (prevoptions.length > 0 && prevoptions[0]?.name && prevoptions[0]?.image)) && (
              <a className="font-size-14 fw-semibold purple-color" onClick={addNewOption}>Add More Option +</a>
            )}
        </div>
        <div class="d-flex justify-content-center gap-2">
          <Button onClick={() => navigate("/questions")} variant="purple-outline" size="small">Cancel</Button>
          <Button onClick={addandEditQuestion} variant="pink" size="small">Save</Button>
        </div>
      </div>
    </>
  )
}

export default AddQuestion