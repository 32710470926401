import { Search, X } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import Button from './Button';

const SearchDropdownFilter = ({applyFilter, resetFilter, children, toggleCheck = false}) => {
    const [toggle, settoggle] = useState(false);

    useEffect(() => {
      if(toggleCheck === true){
        settoggle(false)
      }
    }, [toggleCheck]);

  return (
    <>
    <div class="dropdown wf-search-dropdown">
        <a onClick={() => settoggle(!toggle)} class="d-flex align-items-center justify-content-between">
        <span>Filter and Search</span>
        <span>{toggle ? <X /> : <Search />}</span>
        </a>
        <ul className={`dropdown-menu ${toggle ? 'show' : ''}`}>
            {children}
        <div className="d-flex gap-2 justify-content-end mt-4">
            <Button size="small" variant="purple-outline" onClick={resetFilter}>Reset</Button>
            <Button size="small" variant="pink" onClick={applyFilter}>Search</Button>
        </div>
        </ul>
    </div>
    </>
  )
}

export default SearchDropdownFilter