import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addVendor, deleteBlog, deleteVendor, getBlogList, getProfile } from "../store/apiSlice";
import { Pencil, Trash2 } from "lucide-react";
import Modal from "react-modal";
import Table from "../components/elements/Table";
import Title from "../components/elements/Title";
import Breadcrumb from "../components/elements/Breadcrumb";
import SearchDropdownFilter from "../components/elements/SearchDropdownFilter";
import FloatingInput from "../components/elements/FloatingInput";
import Button from "../components/elements/Button";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const Vendors = () => {
  document.title = "Vendors - Weddified Admin";
  const profile = useSelector(getProfile);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [vendors, setVendors] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [load, setload] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [parentFilter, setparentFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setcategories] = useState(null);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId("");
      setname("");
    } else if (type === "edit") {
      setname(item?.name);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const blogsDelete = async (id) => {
    try {
      await dispatch(deleteVendor(id)).unwrap();
      setIsOpen(false);
      try {
        Vendors();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Vendors = async () => {
    try {
      setload(true);
      setVendors(null);
      const response = await dispatch(addVendor()).unwrap();
      setVendors(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Vendors();
  }, []);

  const applyFilter = () => {
    const filteredResult = categories?.filter((item) => {
      const userName = item?.name.toLowerCase();
      return (
        parentFilter === "" || userName.includes(parentFilter.toLowerCase())
      );
    });

    setFilteredData(filteredResult);
    settoggle(true);
  };

  const resetFilter = () => {
    setparentFilter("");
    setFilteredData(categories);
    settoggle(true);
  };

  return (
    <>
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[{ label: "Menu" }, { label: "Vendors", active: true }]}
        />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Vendors</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3 g-6">
            <SearchDropdownFilter
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              toggleCheck={toggle}
            >
              <FloatingInput
                onChange={(e) => setparentFilter(e.target.value)}
                value={parentFilter}
                placeholder="Store Name"
              />
              <FloatingInput
                onChange={(e) => setparentFilter(e.target.value)}
                value={parentFilter}
                placeholder="Blog Category"
              />
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button variant="purple" size="small">
              Quick Add
            </Button>
            <Link to={'/add-vendor'} className="btn wf-btn wf-purple-btn py-2">
              Add Vendor
            </Link>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Vendors: {vendors?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Store Name</th>
                <th scope="col">Category</th>
                <th scope="col">City</th>
                <th scope="col">Date</th>
                <th scope="col">Author</th>
                <th style={{ width: "200px" }} scope="col">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {load
                ? new Array(12).fill(null).map((_, index) => (
                    <tr>
                      <td colSpan={8}>
                        <div
                          style={{ height: "40px" }}
                          className="w-100 shimmer"
                        ></div>
                      </td>
                    </tr>
                  ))
                : vendors?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            onClick={() => viewModal(item, "edit")}
                            className={`wf-action-icons`}
                          >
                            <Pencil width="18" />
                          </div>
                          <div
                            onClick={() => viewModal(item?._id, "delete")}
                            className={`wf-action-icons`}
                          >
                            <Trash2 width="18" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Vendors;
