import { Camera, ChevronsDown } from 'lucide-react';
import React, { useState } from 'react';

const ImageUpload = ({ prevImages, images, onImageUpload, onImageRemovePrev, onImageRemove, featuredImage, setFeaturedImage }) => {
  const handleImageUpload = (event) => {
    const selectedImages = event.target.files;
    const imagesArray = [];
    for (let i = 0; i < selectedImages.length; i++) {
      imagesArray.push(selectedImages[i]);
    }
    onImageUpload(images.concat(imagesArray));
  };


  return (
    <div className="upload__img-wrap mb-3">
      {/* Display previously uploaded images */}
      {prevImages?.length > 0 && prevImages.map((image, index) => (
        <div className="upload__img-box" key={index}>
          <div className="img-bg">
            <img
              src={`https://res.cloudinary.com/dayfv4et9/image/upload/w_500/q_auto/f_auto/${image}`}
              alt={`Store Image`}
              className="img-bg-size"
            />
            <div className="upload__img-close" onClick={(e) => onImageRemovePrev(e, index)}></div>
            <div className="upload__img-profile">
              <div className="dropdown">
                <a className="">
                  <ChevronsDown />
                </a>
                <ul className="dropdown-menu">
                  <div className="form-check form-check-inline cw-checkbox">
                    <input
                      checked={featuredImage === index}
                      onChange={() => setFeaturedImage(index)}
                      className="form-check-input"
                      type="radio"
                      name="dp"
                      id="dp"
                    />
                    <label className="form-check-label" htmlFor="dp">Featured Image</label>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Display newly uploaded images */}
      {images?.length > 0 && images.map((image, index) => (
        <div className="upload__img-box" key={index}>
          <div className="img-bg">
            <img src={URL.createObjectURL(image)} alt={`Store Image`} className="img-bg-size" />
            <div className="upload__img-close" onClick={(e) => onImageRemove(e, index)}></div>
            <div className="upload__img-profile">
              <div className="dropdown">
                <a className="">
                  <ChevronsDown />
                </a>
                <ul className="dropdown-menu">
                  <div className="form-check form-check-inline cw-checkbox">
                    <input
                      checked={featuredImage === index + prevImages.length}
                      onChange={() => setFeaturedImage(index + prevImages.length)}
                      className="form-check-input"
                      type="radio"
                      name="dp"
                      id="dp"
                    />
                    <label className="form-check-label" htmlFor="dp">Featured Image</label>
                  </div>
                </ul>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* File upload input */}
      <input
        type="file"
        className="btn-check"
        id="btn-check"
        multiple
        data-max_length="20"
        onChange={handleImageUpload}
        accept=".png, .jpg, .jpeg, .webp"
      />
      <label className="upload__btn" htmlFor="btn-check">
        <Camera width={18} strokeWidth={1} /> Add Photos
      </label>
    </div>
  );
};

export default ImageUpload;
