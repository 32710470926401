import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProfile } from '../store/apiSlice'

const ChangePassword = ({ closeModal }) => {
    const profile = useSelector(getProfile) 
    const [old_password, setCurrentPassword] = useState("")
    const [new_password, setNewPassword] = useState("")
    const [confirm_password, setConfirmNewPassword] = useState("")
    const [isSecureEntry, setisSecureEntry] = useState(true)
    const [isSecureEntry2, setisSecureEntry2] = useState(true)
    const [isSecureEntry3, setisSecureEntry3] = useState(true)
    const [load1, setload1] = useState(false);
    const dispatch = useDispatch()
    const handleSubmit = async (e) => {
      e.preventDefault();
      try {
        setload1(true)
          await dispatch(({ old_password, new_password, confirm_password })).unwrap()
          closeModal()
          setCurrentPassword('')
          setNewPassword('')
          setConfirmNewPassword('')
          setload1(false)
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } 
  return (
    <>
    <div class="modal-content">
      <div class="modal-header border-0 pb-0">
        <h1 class="font-size-22 dm-font purple-color">Change Password</h1>
        <button type="button" onClick={closeModal} class="btn-close"></button>
      </div>
      <div className="modal-body">
      <form onSubmit={handleSubmit}>
      <div class="form-floating wf-input wf-input-101 mb-3">
        <input class="form-control" id="opassword" placeholder="Password" value={old_password} onChange={(e) => setCurrentPassword(e.target.value)}
            type={isSecureEntry ? "password" : "text"} autocomplete="password" required />
        <i onClick={() => { setisSecureEntry((prev) => !prev) }} className={`fa-solid purple-color ${isSecureEntry ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
        <label for="opassword">Old Password</label>
      </div>
      <div class="form-floating wf-input wf-input-101 mb-3">
        <input class="form-control" placeholder="Password" value={new_password} id="password" onChange={(e) => setNewPassword(e.target.value)}
            type={isSecureEntry2 ? "password" : "text"} autocomplete="password" required />
        <i onClick={() => { setisSecureEntry2((prev) => !prev) }} className={`fa-solid purple-color ${isSecureEntry2 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
        <label for="password">Password</label>
      </div>
      <div class="form-floating wf-input wf-input-101">
        <input class="form-control" placeholder="Password" value={confirm_password} id="cpassword" onChange={(e) => setConfirmNewPassword(e.target.value)}
            type={isSecureEntry3 ? "password" : "text"} autocomplete="password" required />
        <i onClick={() => { setisSecureEntry3((prev) => !prev) }} className={`fa-solid purple-color ${isSecureEntry3 ? "fa-eye" : "fa-eye-slash"} eye-password`}></i>
        <label for="cpassword">Confirm Password</label>
      </div>
      </form>
      </div>
      <div class="modal-footer border-0 justify-content-center gap-2">
        <button class="btn wf-btn-2 wf-pink-btn py-2 px-4 rounded-2 wf-shadow-2" type="submit" disabled={load1}>{load1 ? (
            <div className="spinner-border wf-spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          ) : (
            "Update"
          )}</button>
      </div>
    </div>
    </> 
  )
}

export default ChangePassword