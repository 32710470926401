import React from 'react';
import { useNavigate } from 'react-router-dom';

const Breadcrumb = ({ items }) => {
  const navigate = useNavigate();

  return (
    <nav aria-label="breadcrumb">
      <ol className="breadcrumb wf-breadcrumb">
        {items.map((item, index) => (
          <li key={index} className={`breadcrumb-item ${item.active ? 'active' : ''}`}  aria-current={item.active ? 'page' : undefined}>
            {item.active ? (item.label) : item.onClick ? (<a onClick={item.onClick}>{item.label}</a>) : item.link ? (<a>{item.label}</a>) : (<a onClick={() => navigate(item.link || '/')}>{item.label}</a>)}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
