import React from 'react';

const DynamicCheckbox = ({
  checked,
  onChange,
  id = "dynamic-checkbox",
  name,
  className = "",           // Additional class names for customization
  required = false,         // Optional required field
  topSpace = 0,             // Top space (0 to 5)
  bottomSpace = 0,          // Bottom space (0 to 5)
  children                  // Label content passed as children
}) => {

  // Validate topSpace and bottomSpace values (only 0 to 5 are allowed)
  const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
  const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);

  return (
    <div className={`form-check wf-checkbox mb-${validBottomSpace} mt-${validTopSpace}`}>
      <input
        className={`form-check-input ${className}`}
        type="checkbox"
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
        required={required}
      />
      <label className="form-check-label" htmlFor={id}>
        {children}
      </label>
    </div>
  );
};

export default DynamicCheckbox;
