import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addAdmin, editAdmin, getAdminList } from '../store/apiSlice';
import Spinner from '../components/Spinner';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';
import FloatingInput from '../components/elements/FloatingInput';

const AddAdmin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  document.title = `${id ? "Edit" : "Add"} Blog - Weddified Admin`

  const [adminDetail, setadminDetail] = useState(null);

  const AdminDetail = async () => {
    try {
      setadminDetail(null);
      const response = await dispatch(getAdminList(id)).unwrap();
      const findAdmin = response?.data?.find(item => item?._id === id)
      setadminDetail(findAdmin);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (id) {
      AdminDetail();
    }
  }, []);

  const [load, setload] = useState(false);
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [imageName, setimageName] = useState("");


  useEffect(() => {
    if (adminDetail) {
      setname(adminDetail?.name)
      setemail(adminDetail?.email)
      setimageName(adminDetail?.imageName)
    }
  }, [adminDetail]);

  const addandEditSubAdmin = async (e) => {
    e.preventDefault();
    try {
      setload(true)
      const formData = new FormData();
      formData.append("id", id)
      formData.append("name", name)
      formData.append("email", email)
      formData.append("password", password)
      formData.append("profilePicture", imageName)
      if (id) {
        await dispatch(editAdmin(formData)).unwrap();
      } else {
        await dispatch(addAdmin(formData)).unwrap();
      }
      navigate("/admins")
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };
  return (
    <>
      {load && <Spinner />}
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "Admins" },
            { label: `${id ? "Edit" : "Add"} Admin`, active: true },
          ]}
        />

        <Title bottomSpace={3}>{id ? "Edit" : "Add"} Admin</Title>
        <div className="radius-20 white-bg wf-shadow p-3 mb-3">
          <div className="row gy-3">
            <div className="col-md-6">
              <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Name" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="email" value={email} onChange={(e) => setemail(e.target.value)} placeholder="Email" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="password" value={password} onChange={(e) => setpassword(e.target.value)} placeholder="Password" />
            </div>
            <div className="col-md-6">
              <FloatingInput type="file" onChange={(e) => setimageName(e.target.files[0])} placeholder="Profile Image" />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-center gap-2">
          <button onClick={() => navigate("/admin")} class="btn wf-btn wf-black-btn py-2 px-4">Cancel</button>
          <button onClick={addandEditSubAdmin} class="btn wf-btn wf-pink-btn py-2 px-4" >{id ? "Update" : "Create"}</button>
        </div>
      </div>

    </>
  )
}

export default AddAdmin