import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteAdmin, getAdminList, getProfile } from "../store/apiSlice";
import { Pencil, Trash2 } from "lucide-react";
import Table from "../components/elements/Table";
import Title from "../components/elements/Title";
import Breadcrumb from "../components/elements/Breadcrumb";
import { Link } from "react-router-dom";
import CustomModal from "../components/elements/Modal";
import DeleteModal from "../components/DeleteModal";

const Admin = () => {
  document.title = "Admins - Weddified Admin";
  const profile = useSelector(getProfile);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [admin, setAdmin] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item) => {
    setIsOpen(true);
    setId(item);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const adminDelete = async (id) => {
    try {
      await dispatch(deleteAdmin(id)).unwrap();
      setIsOpen(false);
      Admins();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Admins = async () => {
    try {
      setload(true);
      setAdmin(null);
      const response = await dispatch(getAdminList()).unwrap();
      setAdmin(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Admins();
  }, []);

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        <DeleteModal closeModal={closeModal} onClick={() => adminDelete(id)} label="Admin" />
      </CustomModal>
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "Admins" },
            { label: "All Admins", active: true },
          ]}
        />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Admins</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3 g-6"></div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Link to={"/add-admin"} className="btn wf-btn wf-purple-btn py-2">Add Admins</Link>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Admins: {admin?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th style={{ width: "100px" }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load
                ? new Array(12).fill(null).map((_, index) => (
                  <tr><td colSpan={4}><div style={{ height: "40px" }} className="w-100 shimmer"></div></td></tr>
                ))
                : admin?.map((item, i) => (
                  <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{item?.name}</td>
                    <td>{item?.email}</td>
                    <td>
                      <div className="d-flex align-items-center gap-2">
                        <Link to={`/edit-admin?id=${item?._id}`} className={`wf-action-icons`}><Pencil width="18" /></Link>
                        <div onClick={() => viewModal(item?._id)} className={`wf-action-icons`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Admin;
