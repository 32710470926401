import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getProfile } from "../store/apiSlice";
import { AlignJustify, ChevronDown, ChevronUp } from "lucide-react";
import sidebarContent from "../utils/sidebarContent.json";

const SideBar = () => {
  const location = useLocation();
  const profile = useSelector(getProfile);
  const { pathname } = location;
  const [toggleButton, SetToggleButton] = useState(false);
  const [togglemenu, settogglemenu] = useState(false);

  const toggleSubButton = (index) => {
    settogglemenu((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  return (
    <>
      <div className={toggleButton ? "sidebar close" : "sidebar"}>
        <div className="logo-details">
          <AlignJustify
            onClick={() => SetToggleButton((prev) => !prev)}
            stroke="var(--color4)"
            strokeWidth={1}
          />
        </div>
        <ul className="nav-links">
          {sidebarContent?.map((item, index) => (
              <li
                key={index}
                className={`${togglemenu[index] ? "showMenu" : ""} ${
                  location?.pathname === item.slug ? "active" : ""
                } ${item.subContent && togglemenu[index] ? "active" : ""}`}
              >
                <div
                  className="icon-link"
                  onClick={() => toggleSubButton(index)}
                >
                  <Link to={item.slug}>
                    <img src={item.icon} alt={item.name} />
                    <span className="link_name">{item.name}</span>
                  </Link>
                  {item?.subContent && (
                    <span class="cursor-pointer">
                      {togglemenu[index] ? <ChevronUp /> : <ChevronDown />}
                    </span>
                  )}
                </div>
                {item?.subContent?.length > 0 && (
                  <ul
                    className={`sub-menu ${togglemenu[index] ? "active" : ""}`}
                  >
                    {togglemenu[index] &&
                      item.subContent.map((subItem, subIndex) => (
                        <li key={subIndex}>
                          <Link
                            className={
                              location?.pathname === subItem.slug
                                ? "active"
                                : ""
                            }
                            to={subItem.slug}
                          >
                            {subItem.name}
                          </Link>
                        </li>
                      ))}
                  </ul>
                )}
              </li>
            ))}
        </ul>
      </div>
    </>
  );
};

export default SideBar;
