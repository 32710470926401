

export const vendorBusinessHolds = [
  {
    id: 1,
    value: "Yes",
  },
  {
    id: 1,
    value: "No",
  },
];

export const vendorBusinessAttributes = [
  {
    id: 1,
    value: 10,
  },
  {
    id: 1,
    value: 10,
  },
  {
    id: 1,
    value: 10,
  },
  {
    id: 1,
    value: 10,
  },
];

export const vendorWeddingType = [
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
];


export const vendorSpecialDiscount = [
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
  {
    id: 1,
    value: "Arab",
  },
];

export const numberofWeddings = [
  {
    id: 1,
    text: '<10',
  },
  {
    id: 2,
    text: '10-25',
  },
  {
    id: 3,
    text: '25-30',
  },
  {
    id: 4,
    text: '50+',
  },
];
export const numberofTeam = [
  {
    id: 5,
    text: '1-2',
  },
  {
    id: 6,
    text: '3-10',
  },
  {
    id: 7,
    text: '11-25',
  },
  {
    id: 8,
    text: '25+',
  },
];

export const BusinessAttributes = [
  {
    id: 9,
    text: 'Emarati-Owned',
  },
  {
    id: 10,
    text: 'Arab-Owned',
  },
  {
    id: 11,
    text: 'Woman-Owned',
  },
  {
    id: 12,
    text: 'Asian-Owned',
  },
];

export const pricingArray=["Less than 50", "From 50 to 100", "From 100 to 150", "From 150 to 250", "From 250 to 350", "From 350 to 500", "From 500 to 750", "From 750 to 1000", "More than 1000"]
