import React, { useEffect, useState } from 'react'
import { addCategory, deleteCategory, editCategory, getCategoryList } from '../store/apiSlice';
import { useDispatch } from 'react-redux';
import { Pencil, Trash2 } from "lucide-react";
import CustomModal from '../components/elements/Modal';
import ModalContent from '../components/elements/ModalContent';
import FloatingInput from '../components/elements/FloatingInput';
import DeleteModal from '../components/DeleteModal';
import SearchDropdownFilter from '../components/elements/SearchDropdownFilter';
import Button from '../components/elements/Button';
import DynamicSelect from '../components/elements/DynamicSelect';
import Table from '../components/elements/Table';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';

const Categories = () => {
  document.title = "Categories - Weddified Admin"
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [categories, setcategories] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [pricingType, setpricingType] = useState("");
  const [parentId, setparentId] = useState("");
  const [cover, setcover] = useState("");
  const [image, setimage] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);
  const pricingTypes = [
    { id: 1, name: "Pricing", value: "pricing" },
    { id: 2, name: "Deal", value: "deal" },
  ]

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId("");
      setname("");
      setparentId("");
      setpricingType("");
      setcover("");
      setimage("");
    } else if (type === "edit") {
      setId(item?._id);
      setname(item?.name);
      setpricingType(item?.pricingType);
      setparentId(item?.parentId?._id || "");
      setcover(item?.cover);
      setimage(item?.image);
    } else if (type === "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setparentId("");
    setpricingType("");
    setcover("");
    setimage("");
  }

  const categoryDelete = async (id) => {
    try {
      await dispatch(deleteCategory(id)).unwrap();
      setIsOpen(false);
      setFilteredData((prev) => prev?.filter(item => item?._id !== id));
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      setload(true);
      setcategories(null);
      setFilteredData(null);
      const response = await dispatch(getCategoryList()).unwrap();
      setcategories(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Categories();
  }, []);

  const addandEditCategory = async () => {
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("parentId", parentId);
      data.append("pricingType", pricingType);

      // Ensure cover and image are files, not empty strings
      if (cover instanceof File) data.append("cover", cover);
      if (image instanceof File) data.append("icon", image);
      

      if (id) {
        await dispatch(editCategory(data)).unwrap();
      } else {
        await dispatch(addCategory(data)).unwrap();
      }

      setIsOpen(false);
      Categories();
      setname("");
      setparentId("");
      setpricingType("");
      setcover(null);
      setimage(null);
    } catch (error) {
      console.error("Error:", error);
    }
  };


  const [parentFilter, setparentFilter] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = categories?.filter(item => {
      const userName = item?.name.toLowerCase();
      return (
        (parentFilter === '' || userName.includes(parentFilter.toLowerCase()))
      );
    });

    setFilteredData(filteredResult);
    settoggle(true);
  }

  const resetFilter = () => {
    setparentFilter('');
    setFilteredData(categories)
    settoggle(true);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {(modalType === "add" || modalType === "edit") ?
          <ModalContent title={`${modalType === "add" ? "Add" : "Edit"} Category / Sub-Category`} closeModal={closeModal} onClick={addandEditCategory} buttonText={modalType === "add" ? "Submit" : "Update"}>
            <FloatingInput value={name} onChange={(e) => setname(e.target.value)} placeholder="Category / Sub-Category Name" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setimage(e.target.files[0])} placeholder="Category / Sub-Category Icon" bottomSpace={3} />
            <FloatingInput type="file" onChange={(e) => setcover(e.target.files[0])} placeholder="Category / Sub-Category Banner" bottomSpace={3} />
            <DynamicSelect value={parentId} onChange={(e) => setparentId(e.target.value)} optionLabel='name' optionValue='_id' options={categories} placeholder="Select Parent Category" bottomSpace={3} />
            <DynamicSelect value={pricingType} onChange={(e) => setpricingType(e.target.value)} optionLabel='name' optionValue='value' options={pricingTypes} placeholder="Select Pricing Type" bottomSpace={3} />
          </ModalContent>
          : modalType === "delete" ?
            <DeleteModal closeModal={closeModal} onClick={() => categoryDelete(id)} label="Category / Sub-Category" />
            : null}
      </CustomModal>


      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb items={[{ label: 'Menu', }, { label: 'System Config', }, { label: 'Categories', active: true }]} />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Categories</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter applyFilter={applyFilter} resetFilter={resetFilter} toggleCheck={toggle}>
              <FloatingInput onChange={e => setparentFilter(e.target.value)} value={parentFilter} placeholder="Search Category" />
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button variant="purple" size="small" onClick={() => viewModal(null, "add")}>Add Category</Button>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Categories / Sub-Categories : {categories?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Category / Sub-Category Name</th>
                <th scope="col">Parent Category</th>
                <th scope="col">Icon</th>
                <th scope="col">Cover Image</th>
                <th scope="col">Created By</th>
                <th style={{ width: '100px' }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load ? new Array(12).fill(null).map((_, index) => (<tr><td colSpan={7}><div style={{ height: '60px' }} className='w-100 shimmer'></div></td></tr>)) : filteredData?.map((item, i) => (
                <tr key={i}>
                  <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.name ? item?.name : ""}</td>
                  <td style={{ verticalAlign: "middle" }}>{item?.parentId === null ? "" : item?.parentId?.name}</td>
                  <td style={{ verticalAlign: "middle" }}><img src={`${process.env.REACT_APP_IMGURL}${item?.icon}`} width={50} alt="" /></td>
                  <td style={{ verticalAlign: "middle" }}><img src={`${process.env.REACT_APP_IMGURL}${item?.cover}`} width={100} alt="" /></td>
                  <td style={{ verticalAlign: "middle" }}>{item?.createdBy?.name}</td>
                  <td style={{ verticalAlign: "middle" }}>
                    <div className="d-flex align-items-center gap-2">
                      <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                      <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
                    </div>
                  </td>
                </tr>))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}

export default Categories