import { Eye, EyeOff } from 'lucide-react';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { signIn } from '../store/apiSlice';
import FloatingInput from '../components/elements/FloatingInput';
import Button from '../components/elements/Button';

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSecureEntry, setisSecureEntry] = useState(true)
  const [load, setload] = useState(false)


  const handleSubmit = async (e) => {
    e.preventDefault();
    setload(true)
    try {
      const payload = {
        email,
        password,
        role: "admin",
      }
      await dispatch(signIn(payload)).unwrap();
      navigate('/dashboard');
      setload(false)
    } catch (rejectedValueOrSerializedError) {
      setload(false)
      console.log(rejectedValueOrSerializedError);
    }
  };

  document.title = "Login - Weddified Admin"

  return (
    <>
      <section class="vh-100 authentication-bg">
        <div class="container py-5 h-100">
          <div class="row d-flex justify-content-center align-items-center h-100">
            <div class="col-12 col-md-8 col-lg-6 col-xl-5">
              <div class="card wf-shadow radius-10 border-0">
                <div class="card-body p-5 text-center">
                  <img alt="Shadi Tayari" className="mx-auto d-block auth-logo mb-5" width="280" src={"../assets/images/logo.svg"} />
                  <form onSubmit={handleSubmit}>
                    <FloatingInput type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" bottomSpace={3} />
                    <FloatingInput type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" bottomSpace={4} />
                    <Button type="submit" variant="purple" fullwidth loading={load}>Login</Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Login