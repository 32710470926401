import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { addBlog, getBlogCategory, getBlogDetail, getCategoryList, uploadAttachment } from '../store/apiSlice';
import FloatingInput from '../components/elements/FloatingInput';
import DynamicSelect from '../components/elements/DynamicSelect';
import Button from '../components/elements/Button';
import HtmlTextEditor from '../components/elements/HtmlTextEditor';
import FloatingTextArea from '../components/elements/FloatingTextArea';
import Title from '../components/elements/Title';
import Breadcrumb from '../components/elements/Breadcrumb';

const AddBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  document.title = `${id ? "Edit" : "Add"} Blog - Weddified Admin`

  const [blogdetail, setblogdetail] = useState(null);
  const [blogsCategory, setBlogsCategory] = useState(null);
  const [categories, setcategories] = useState(null);


  const BlogDetail = async () => {
    try {
      setblogdetail(null);
      const response = await dispatch(getBlogDetail(id)).unwrap();
      setblogdetail(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    if (id) {
      BlogDetail();
    }
  }, []);

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getCategoryList()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const BlogsCategories = async () => {
    try {
      setBlogsCategory(null);
      const response = await dispatch(getBlogCategory()).unwrap();
      setBlogsCategory(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    BlogsCategories();
    Categories();
  }, []);

  const [formData, setFormData] = useState({
    categoryId: '',
    blogCategoryId: '',
    title: '',
    excerpt: '',
    description: '',
    featureImage: null,
    publishDate: new Date().toISOString().split('T')[0],
    metaTitle: '',
    metaDescription: '',
    metaKeywords: [],
    status: 'draft',
    tags: [],
    readingTime: 0,
  });
  const [currentTag, setCurrentTag] = useState('');

  // Handle input changes
  const handleInputChange = (field) => (e) => {
    const value = e.target.type === 'file' ? e.target.files[0] : e.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
  };

  // Handle tag addition
  const handleAddTag = () => {
    if (currentTag && !formData.tags.includes(currentTag)) {
      setFormData(prev => ({
        ...prev,
        tags: [...prev.tags, currentTag],
      }));
      setCurrentTag('');
    }
  };

  // Handle tag removal
  const handleRemoveTag = (tagToRemove) => {
    setFormData(prev => ({
      ...prev,
      tags: prev.tags.filter(tag => tag !== tagToRemove),
    }));
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      // Upload feature image if present
      let featureImageUrl = formData.featureImage;
      if (formData.featureImage && typeof formData.featureImage !== 'string') {
        const imageFormData = new FormData();
        imageFormData.append('image', formData.featureImage);

        const response = await dispatch(uploadAttachment(formData)).unwrap();
        featureImageUrl = response?.data;
      }

      // Prepare blog data
      const blogData = {
        ...formData,
        featureImage: featureImageUrl,
        metaKeywords: formData.metaKeywords.length ? formData.metaKeywords.split(',') : [],
      };

      // Submit to API
      await dispatch(addBlog(blogData)).unwrap()
      navigate('/blogs');
    } catch (error) {
      console.error('Error creating blog:', error);
    }
  };

  useEffect(() => {
    if (blogdetail) {
      setFormData({
        categoryId: blogdetail?.categoryId?._id,
        blogCategoryId: blogdetail?.blogCategoryId?._id,
        title: blogdetail?.title,
        excerpt: blogdetail?.excerpt,
        description: blogdetail?.description,
        featureImage: blogdetail?.featureImage || null,
        publishDate: blogdetail?.publishDate,
        metaTitle: blogdetail?.metaTitle,
        metaDescription: blogdetail?.metaDescription,
        metaKeywords: blogdetail?.metaKeywords || [],
        status: blogdetail?.status,
        tags: blogdetail?.tags?.map(data => data?.name) || [],
        readingTime: blogdetail?.readingTime,
      })
    }
  }, [blogdetail]);

  return (
    <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
      <Breadcrumb
        items={[
          { label: "Menu" },
          { label: "Blogs" },
          { label: `${id ? "Edit" : "Add"} Blog`, active: true },
        ]}
      />
      <Title bottomSpace={3}>{id ? "Edit" : "Add"} Blog</Title>
      <div className="radius-20 white-bg wf-shadow p-3 mb-3">
        <div className="row gy-3">
          <div className="col-md-12">
            <FloatingInput
              value={formData.title}
              onChange={handleInputChange('title')}
              placeholder="Blog Title"
            />
          </div>
          <div className="col-md-12">
            <FloatingTextArea
              height="100"
              value={formData.excerpt}
              onChange={handleInputChange('excerpt')}
              placeholder="Blog Excerpt"
            />
          </div>
          <div className="col-md-6">
            <DynamicSelect
              options={blogsCategory}
              value={formData.blogCategoryId}
              optionLabel='name'
              optionValue='_id'
              onChange={(e) => setFormData(prev => ({ ...prev, blogCategoryId: e.target.value }))}
              placeholder="Blog Category"
            />
          </div>
          <div className="col-md-6">
            <DynamicSelect
              options={categories}
              value={formData.categoryId}
              optionLabel='name'
              optionValue='_id'
              onChange={(e) => setFormData(prev => ({ ...prev, categoryId: e.target.value }))}
              placeholder="Category"
            />
          </div>
          <div className="col-md-6">
            <FloatingInput
              type="file"
              onChange={handleInputChange('featureImage')}
              placeholder="Featured Image"
            />
          </div>
          <div className="col-md-6">
            <FloatingInput
              type="date"
              value={formData.publishDate}
              onChange={handleInputChange('publishDate')}
              placeholder="Publish Date"
            />
          </div>
          <div className="col-md-12">
            <FloatingInput
              value={formData.metaTitle}
              onChange={handleInputChange('metaTitle')}
              placeholder="Meta Title"
            />
          </div>
          <div className="col-md-12">
            <FloatingTextArea
              height="100"
              value={formData.metaDescription}
              onChange={handleInputChange('metaDescription')}
              placeholder="Meta Description"
            />
          </div>
          <div className="col-md-12">
            <FloatingInput
              value={formData?.metaKeywords}
              onChange={handleInputChange('metaKeywords')}
              placeholder="Meta Keywords (comma-separated)"
            />
          </div>
          <div className="col-md-11">
            <FloatingInput
              value={currentTag}
              onChange={(e) => setCurrentTag(e.target.value)}
              placeholder="Add Tags"
            />
          </div>
          <div className="col-md-1 align-self-center">
            <Button variant="pink" size="large" onClick={handleAddTag}>
              Add
            </Button>
          </div>
          <div className="col-md-12">
            <div className="d-flex flex-wrap gap-2">
              {formData.tags.map((tag, index) => (
                <span key={index} className="badge bg-secondary">
                  {tag}
                  <button
                    type="button"
                    className="btn-close btn-close-white ms-2"
                    onClick={() => handleRemoveTag(tag)}
                  />
                </span>
              ))}
            </div>
          </div>
          <div className="col-md-12">
            <FloatingInput
              type="number"
              value={formData.readingTime}
              onChange={handleInputChange('readingTime')}
              placeholder="Reading Time (minutes)"
            />
          </div>
          <div className="col-md-12">
            <DynamicSelect
              options={[
                { value: 'draft', label: 'Draft' },
                { value: 'pending', label: 'Pending' },
                { value: 'published', label: 'Published' },
                { value: 'archived', label: 'Archived' },
              ]}
              value={formData.status}
              onChange={(e) => setFormData(prev => ({ ...prev, status: e.target.value }))}
              placeholder="Status"
            />
          </div>
          <div className="col-md-12">
            <HtmlTextEditor
              label="Blog Content"
              value={formData.description}
              onChange={(value) => setFormData(prev => ({ ...prev, description: value }))}
            />
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center gap-3">
        <Button variant="purple-outline" size="small" onClick={()=> navigate("/blogs")}>
          Cancel
        </Button>
        <Button variant="pink" size="small" onClick={handleSubmit}>
          {id ? "Update" : "Submit"}
        </Button>
      </div>
    </div>
  );
};

export default AddBlog;