import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContactQueries,
  getContactQueriesList,
  getProfile,
} from "../store/apiSlice";
import { Pencil, Trash2 } from "lucide-react";
import Modal from "react-modal";
import Table from "../components/elements/Table";
import Title from "../components/elements/Title";
import Breadcrumb from "../components/elements/Breadcrumb";

Modal.setAppElement("#root");

const ContactForm = () => {
  document.title = "Contact Form - Weddified Admin";
  const profile = useSelector(getProfile);
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [contactForm, setContactForm] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [load, setload] = useState(false);
  const [toggle, settoggle] = useState(false);
  const [parentFilter, setparentFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [categories, setcategories] = useState(null);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId("");
      setname("");
    } else if (type === "edit") {
      setname(item?.name);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
  }

  const blogsDelete = async (id) => {
    try {
      await dispatch(deleteContactQueries(id)).unwrap();
      setIsOpen(false);
      try {
        reportListings();
      } catch (rejectedValueOrSerializedError) {
        console.log(rejectedValueOrSerializedError);
      }
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const reportListings = async () => {
    try {
      setload(true);
      setContactForm(null);
      const response = await dispatch(getContactQueriesList()).unwrap();
      setContactForm(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    reportListings();
  }, []);

  const applyFilter = () => {
    const filteredResult = categories?.filter((item) => {
      const userName = item?.name.toLowerCase();
      return (
        parentFilter === "" || userName.includes(parentFilter.toLowerCase())
      );
    });

    setFilteredData(filteredResult);
    settoggle(true);
  };

  const resetFilter = () => {
    setparentFilter("");
    setFilteredData(categories);
    settoggle(true);
  };

  return (
    <>
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "Contact Queries", active: true },
          ]}
        />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Contact Queries</Title>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">
            Contact Queries: {contactForm?.length}
          </p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                <th scope="col">Reason</th>
                <th scope="col">Date</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load
                ? new Array(12).fill(null).map((_, index) => (
                    <tr>
                      <td colSpan={7}>
                        <div
                          style={{ height: "40px" }}
                          className="w-100 shimmer"
                        ></div>
                      </td>
                    </tr>
                  ))
                : contactForm?.map((item, i) => (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{item?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>{item?.user_id?.name}</td>
                      <td>
                        <div className="d-flex align-items-center gap-2">
                          <div
                            onClick={() => viewModal(item, "edit")}
                            className={`wf-action-icons`}
                          >
                            <Pencil width="18" />
                          </div>
                          <div
                            onClick={() => viewModal(item?._id, "delete")}
                            className={`wf-action-icons`}
                          >
                            <Trash2 width="18" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
