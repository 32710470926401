import React, { useEffect, useState } from "react";
import {
  addAttribute,
  deleteAttribute,
  editAttribute,
  getAttributeList,
  getCategoryList,
  getProfile,
} from "../store/apiSlice";
import { Pencil, Trash2 } from "lucide-react";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import CustomModal from "../components/elements/Modal";
import ModalContent from "../components/elements/ModalContent";
import FloatingInput from "../components/elements/FloatingInput";
import DynamicSelect from "../components/elements/DynamicSelect";
import DeleteModal from "../components/DeleteModal";
import Title from "../components/elements/Title";
import SearchDropdownFilter from "../components/elements/SearchDropdownFilter";
import Table from "../components/elements/Table";
import Breadcrumb from "../components/elements/Breadcrumb";
import Button from "../components/elements/Button";

Modal.setAppElement("#root");

const Attributes = () => {
  document.title = "Attributes - Weddified Admin";
  const [id, setId] = useState("");
  const dispatch = useDispatch();
  const [categories, setcategories] = useState(null);
  const [attributes, setattributes] = useState(null);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const [name, setname] = useState("");
  const [parentId, setparentId] = useState("");
  const [categoryId, setcategoryId] = useState("");
  const [image, setimage] = useState("");
  const [toggle, settoggle] = useState(false);
  const [load, setload] = useState(false);

  const viewModal = async (item, type, feature) => {
    setIsOpen(true);
    if (type === "add") {
      setId("");
      setname("");
      setparentId("");
      setcategoryId("");
      setimage("");
    } else if (type === "edit") {
      setname(item?.name);
      setparentId(item?.parentId?._id || "");
      setcategoryId(item?.categoryId?._id);
      setimage(item?.icon);
      setId(item?._id);
    } else if (type == "delete") {
      setId(item);
    }
    setModalType(type);
    setIsOpen(true);
  };

  function closeModal() {
    setIsOpen(false);
    setId("");
    setname("");
    setparentId("");
    setcategoryId("");
    setimage("");
  }

  const attributeDelete = async (id) => {
    try {
      await dispatch(deleteAttribute(id)).unwrap();
      setIsOpen(false);
      Attributes();
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Attributes = async () => {
    try {
      setload(true);
      setattributes(null);
      setFilteredData(null);
      const response = await dispatch(getAttributeList()).unwrap();
      setattributes(response?.data);
      setFilteredData(response?.data);
      setload(false);
    } catch (rejectedValueOrSerializedError) {
      setload(false);
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getCategoryList()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Attributes();
    Categories();
  }, []);

  const addandEditCategory = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("id", id);
      data.append("name", name);
      data.append("parentId", parentId);
      data.append("categoryId", categoryId);
      data.append("icon", image);
      if (id) {
        await dispatch(editAttribute(data)).unwrap();
      } else {
        await dispatch(addAttribute(data)).unwrap();
      }
      setId("");
      setIsOpen(false);
      Attributes();
      setname("");
      setparentId("");
      setcategoryId("");
      setimage("");
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const [parentFilter, setparentFilter] = useState("");
  const [catFilter, setcatFilter] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const applyFilter = () => {
    const filteredResult = attributes?.filter((item) => {
      const userName = item?.parentId?.name.toLowerCase();
      const catName = item?.categoryId?.name.toLowerCase();

      return (
        (parentFilter === "" ||
          userName.includes(parentFilter.toLowerCase())) &&
        (catFilter === "" || catName.includes(catFilter.toLowerCase()))
      );
    });
    setFilteredData(filteredResult);
    settoggle(false);
  };
  const resetFilter = () => {
    setparentFilter("");
    setcatFilter("");
    setFilteredData(attributes);
    settoggle(false);
  };

  return (
    <>
      <CustomModal modalIsOpen={modalIsOpen} closeModal={closeModal}>
        {modalType === "add" || modalType === "edit" ? (
          <ModalContent
            title={`${modalType === "add" ? "Add" : "Edit"
              } Attribute / Sub-Attribute`}
            closeModal={closeModal}
            onClick={addandEditCategory}
            buttonText={modalType === "add" ? "Submit" : "Update"}
          >
            <FloatingInput
              value={name}
              onChange={(e) => setname(e.target.value)}
              placeholder="Attribute Name"
              bottomSpace={3}
            />
            <DynamicSelect
              value={parentId}
              onChange={(e) => setparentId(e.target.value)}
              optionLabel="name"
              optionValue="_id"
              options={attributes}
              placeholder="Select Parent Attribute"
              bottomSpace={3}
            />
            <DynamicSelect
              bottomSpace={3}
              value={categoryId}
              onChange={(e) => setcategoryId(e.target.value)}
              optionLabel="name"
              optionValue="_id"
              options={categories}
              placeholder="Select Category"
            />
            <FloatingInput
              type="file"
              onChange={(e) => setimage(e.target.files[0])}
              placeholder="Attribute / Sub-Attribute Icon"
              bottomSpace={3}
            />
          </ModalContent>
        ) : modalType === "delete" ? (
          <DeleteModal
            closeModal={closeModal}
            onClick={() => attributeDelete(id)}
            label="Attribute/Sub-Attribute"
          />
        ) : null}
      </CustomModal>

      <div className="wf-attribute-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "System Config" },
            { label: "Attributes", active: true },
          ]}
        />
        <div className="row gy-3 mb-4 align-items-center mb-2">
          <div className="col-md-3 col-6 align-self-center order-1">
            <Title>Attributes</Title>
          </div>
          <div className="col-md-6 order-md-2 order-3">
            <SearchDropdownFilter
              applyFilter={applyFilter}
              resetFilter={resetFilter}
              toggleCheck={toggle}
            >
              <FloatingInput
                onChange={(e) => setparentFilter(e.target.value)}
                value={parentFilter}
                placeholder="Search Attribute"
              />
            </SearchDropdownFilter>
          </div>
          <div className="col-md-3 col-6 order-md-3 order-2 d-flex flex-row align-items-center gap-3 justify-content-end">
            <Button
              variant="purple"
              size="small"
              onClick={() => viewModal(null, "add")}
            >
              Add Attribute
            </Button>
          </div>
        </div>
        <div className="radius-20 white-bg wf-shadow p-3">
          <p className="font-size-20 pink-color">Attribute/Sub-Attribute : {attributes?.length}</p>
          <Table>
            <thead>
              <tr>
                <th scope="col">S.No</th>
                <th scope="col">Attribute/Sub-Attribute Name</th>
                <th scope="col">Parent Category</th>
                <th scope="col">Category</th>
                <th scope="col">Icon</th>
                <th scope="col">Created By</th>
                <th style={{ width: "100px" }} scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {load
                ? new Array(12).fill(null).map((_, index) => (
                  <tr>
                    <td colSpan={7}>
                      <div
                        style={{ height: "60px" }}
                        className="w-100 shimmer"
                      ></div>
                    </td>
                  </tr>
                ))
                : filteredData?.map((item, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle" }}>{i + 1}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.name ? item?.name : ""}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.parentId == null ? "" : item?.parentId?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>{item?.categoryId?.name}</td>
                    <td style={{ verticalAlign: "middle" }}><img src={`${process.env.REACT_APP_IMGURL}${item?.icon}`} width={50} alt="" /></td>
                    <td style={{ verticalAlign: "middle" }}>{item?.createdBy?.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      <div className="d-flex align-items-center gap-2">
                        <div onClick={() => viewModal(item, "edit")} className={`wf-action-icons`}><Pencil width="18" /></div>
                        <div onClick={() => viewModal(item?._id, "delete")} className={`wf-action-icons`}><Trash2 width="18" /></div>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default Attributes;
