import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import Store from './store';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
      <Toaster
        position='top-center'
        reverseOrder={false}
        toastOptions={{
          duration: 1500
        }}
      />
    </Provider>
  </React.StrictMode>
);
reportWebVitals();
