import React, { useEffect, useState } from "react";
import Breadcrumb from "../components/elements/Breadcrumb";
import Title from "../components/elements/Title";
import FloatingInput from "../components/elements/FloatingInput";
import {
  BusinessAttributes,
  numberofTeam,
  numberofWeddings,
  vendorBusinessAttributes,
  vendorBusinessHolds,
  vendorSpecialDiscount,
  vendorWeddingType,
} from "../utils/Helper";
import SelectButton from "../components/elements/SelectButton";
import FloatingTextArea from "../components/elements/FloatingTextArea";
import DynamicCheckbox from "../components/elements/DynamicCheckbox";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import DynamicSelect from "../components/elements/DynamicSelect";
import ImageUpload from "../components/elements/ImageUpload";
import Button from "../components/elements/Button";
import { X } from "lucide-react";
import { Trash2 } from "lucide-react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getAttributeList, getCategoryList, getEthnicsList } from "../store/apiSlice";

const AddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  document.title = `${id ? "Edit" : "Add"} Vendor - Weddified Admin`

  const [categories, setcategories] = useState(null);
  const [attributes, setattributes] = useState(null);
  const [ethnics, setethnics] = useState(null);

  const Attributes = async () => {
    try {
      setattributes(null);
      const response = await dispatch(getAttributeList()).unwrap();
      setattributes(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Categories = async () => {
    try {
      setcategories(null);
      const response = await dispatch(getCategoryList()).unwrap();
      setcategories(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  const Ethnics = async () => {
    try {
      setethnics(null);
      const response = await dispatch(getEthnicsList()).unwrap();
      setethnics(response?.data);
    } catch (rejectedValueOrSerializedError) {
      console.log(rejectedValueOrSerializedError);
    }
  };

  useEffect(() => {
    Attributes();
    Categories();
    Ethnics();
  }, []);

  const [businessName, setBusinessName] = useState('');
  const [date, setDate] = useState('');
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [attribute, setAttribute] = useState('');
  const [briefDesc, setBriefDesc] = useState('');
  const [detailDesc, setDetailDesc] = useState('');
  const [hosted, setHosted] = useState('');
  const [member, setMember] = useState('');
  const [publicHolds, setPublicHolds] = useState('');
  const [businessAttribute, setBusinessAttribute] = useState('');
  const [minRates, setMinRates] = useState('');
  const [maxRate, setMaxRate] = useState('');
  const [weddingType, setWeddingType] = useState('');
  const [contact, setContact] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [website, setWebsite] = useState('');
  const [address, setAddress] = useState('');
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');
  const [facebook, setFacebook] = useState('');
  const [x, setX] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [instagram, setInstagram] = useState('');
  const [youtube, setYoutube] = useState('');
  const [pinterest, setPinterest] = useState('');
  const [addFaq, setAddFaq] = useState([{ question: '', answer: '' }]);
  const [discount, setDiscount] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [links, setLinks] = useState([]);
  const [suggestedVendors, setSuggestedVendors] = useState('');
  const [images, setImages] = useState([]);
  const [location, setLocation] = useState(null);

  const addVideo = () => {
    if (videoUrl) {
      let platform = "";
      if (videoUrl.includes("youtube.com") || videoUrl.includes("youtu.be")) {
        platform = "YouTube";
      } else if (videoUrl.includes("dailymotion.com")) {
        platform = "Dailymotion";
      } else if (videoUrl.includes("vimeo.com")) {
        platform = "Vimeo";
      }

      setLinks([...links, { url: videoUrl, platform }]);
      setVideoUrl("");
    }
  };

  const getEmbedUrl = (url) => {
    if (url.includes("youtube.com") || url.includes("youtu.be")) {
      const videoId = url.split("v=")[1]?.split("&")[0] || url.split(".be/")[1];
      return `https://www.youtube.com/embed/${videoId}`;
    } else if (url.includes("dailymotion.com")) {
      const videoId = url.split("video/")[1]?.split("_")[0];
      return `https://www.dailymotion.com/embed/video/${videoId}`;
    } else if (url.includes("vimeo.com")) {
      const videoId = url.split("/").pop();
      return `https://player.vimeo.com/video/${videoId}`;
    }
    return null;
  };

  const handleAddFaq = () => {
    setAddFaq([...addFaq, { question: "", answer: "" }]);
  };

  const deleteFaq = (index) => {
    const check = addFaq.filter((_, i) => i !== index);
    setAddFaq(check);
  };

  const handleChange = (index, field, value) => {
    const updatedFaqs = [...addFaq];
    updatedFaqs[index][field] = value;
    setAddFaq(updatedFaqs);
  };

  // Check if the last FAQ has both fields filled
  const lastFaq = addFaq[addFaq.length - 1];
  const checkFaq =
    lastFaq.question.trim() !== "" && lastFaq.answer.trim() !== "";

  const removeVideo = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const supplierData = {
      name: businessName,
      businessStartDate: date,
      category, // Should be an ObjectId in production
      subCategory, // Should be an ObjectId in production
      attributes: [attribute], // Should be array of ObjectIds
      overview: briefDesc,
      description: detailDesc,
      weddingsHosted: parseInt(hosted.split('-')[0]) || 0,
      teamMembers: parseInt(member.split('-')[0]) || 0,
      insurance: publicHolds === 'Yes',
      minPrice: parseFloat(minRates) || null,
      maxPrice: parseFloat(maxRate) || null,
      businessEthnics: [businessAttribute], // Should be array of ObjectIds
      contactPerson: contact,
      phone,
      email,
      mobile,
      whatsapp: mobile, // Assuming same as mobile if checked
      website,
      address,
      country, // Should be an ObjectId
      city, // Should be an ObjectId
      // Add latitude/longitude from Google Places if available
      latitude: location?.lat || null,
      longitude: location?.lng || null,
      socialLinks: {
        facebook,
        twitter: x,
        linkedin,
        instagram,
        youtube,
        pinterest
      },
      faqs: addFaq.map(faq => ({ question: faq.question, answer: faq.answer })), // Should reference SupplierFAQ model
      discount: parseFloat(discount) || 0,
      images, // From ImageUpload component
      videos: links.map(link => link.url),
      suggestedSuppliers: suggestedVendors ? [suggestedVendors] : [] // Should be array of ObjectIds
    };

    try {

      console.log('Supplier created:', supplierData);
      // Reset form or redirect user
      alert('Supplier created successfully!');
    } catch (error) {
      console.error('Error creating supplier:', error.response?.data || error.message);
      alert('Error creating supplier: ' + (error.response?.data?.message || error.message));
    }
  };


  return (
    <>
      <div className="wf-category-list-page px-md-5 pt-3 px-3 pb-5">
        <Breadcrumb
          items={[
            { label: "Menu" },
            { label: "Vendors" },
            { label: "Add Vendor", active: true },
          ]}
        />
        <Title bottomSpace={3}>Add Vendor</Title>
        <div className="radius-20 white-bg wf-shadow p-3 mb-3">
          {/* Business Details */}
          <Title bottomSpace={3} className={"text-dark fs-3"}>Business Details</Title>
          <div className="row gy-3">
            <div className="col-md-5">
              <FloatingInput onChange={(e) => setBusinessName(e.target.value)} value={businessName} placeholder="Business Name" />
            </div>
            <div className="col-md-3">
              <FloatingInput type="date" onChange={(e) => setDate(e.target.value)} placeholder="Operating Since" value={date} />
            </div>
            <div className="col-md-6">
              <Title bottomSpace={3} className={"fs-5"}>Type of Category</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {categories?.filter(category => category?.parentId == null)?.map((value, index) => (
                  <SelectButton key={index} id={`${value?._id}-${index + 1}-category`} label={value?.name} onChange={(e) => setCategory(e.target.value)} checked={category === value?._id} value={value?._id} name="category" />
                ))}
              </div>
              {category && <>
                <Title bottomSpace={3} className={"fs-5"}>Type of Sub Category</Title>
                <div className="d-flex flex-wrap gap-2 mb-3">
                  {categories?.filter(subCat => subCat?.parentId?._id === category)?.map((value, index) => (
                    <SelectButton key={index} id={`${value?._id}-${index + 1}-subCategory`} label={value?.name} onChange={(e) => setSubCategory(e.target.value)} checked={subCategory === value?._id} value={value?._id} name="subCategory" />
                  ))}
                </div>
                <Title bottomSpace={3} className={"fs-5"}>Attributes</Title>
                <div className="d-flex flex-wrap gap-2 mb-3">
                  {attributes?.filter(attr => attr?.parentId?._id == null && attr?.categoryId?._id === category)?.map((value, index) => (
                    <SelectButton key={index} id={`${value?._id}-${index + 1}-attribute`} label={value?.name} onChange={(e) => setAttribute(e.target.value)} checked={attribute === value?._id} value={value?._id} name="attribute" />
                  ))}
                </div>
              </>}
            </div>
            <div className="col-md-6">
              <FloatingTextArea bottomSpace={3} height="180" onChange={(e) => setBriefDesc(e.target.value)} value={briefDesc} placeholder="Brief Overview" />
              <FloatingTextArea height="290" onChange={(e) => setDetailDesc(e.target.value)} value={detailDesc} placeholder="Detail Description (Inc Product & Service)" />
            </div>
            <div className="col-md-6">
              <Title bottomSpace={3} className={"fs-5"}>Number of Wedding Hosted</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {numberofWeddings.map((value, index) => (
                  <SelectButton key={index} id={`${value.text}-${index + 1}-hosted`} label={value.text} onChange={(e) => setHosted(e.target.value)} checked={hosted === value.text} value={value.text} name="hosted" />
                ))}
              </div>
              <Title bottomSpace={3} className={"fs-5"}>Number of Team Members</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {numberofTeam.map((value, index) => (
                  <SelectButton key={index} id={`${value.text}-${index + 1}-member`} label={value.text} onChange={(e) => setMember(e.target.value)} checked={member === value.text} value={value.text} name="member" />
                ))}
              </div>
              <Title bottomSpace={3} className={"fs-5"}>Business Holds Public Insurance</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {vendorBusinessHolds.map((value, index) => (
                  <SelectButton key={index} id={`${value.value}-${index + 1}-publicHolds`} label={value.value} onChange={(e) => setPublicHolds(e.target.value)} checked={publicHolds === value.value} value={value.value} name="publicHolds" />
                ))}
              </div>
              <Title bottomSpace={3} className={"fs-5"}>Business Attributes</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {BusinessAttributes.map((value, index) => (
                  <SelectButton key={index} id={`${value.text}-${index + 1}-businessAttribute`} label={value.text} onChange={(e) => setBusinessAttribute(e.target.value)} checked={businessAttribute === value.text} value={value.text} name="businessAttributes" />
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <Title bottomSpace={3} className={"fs-5"}>Business Services Price Range</Title>
              <div className="row">
                <div className="col-md-6">
                  <FloatingInput bottomSpace={2} onChange={(e) => setMinRates(e.target.value)} value={minRates} placeholder="Min Rates" />
                </div>
                <div className="col-md-6">
                  <FloatingInput bottomSpace={2} onChange={(e) => setMaxRate(e.target.value)} value={maxRate} placeholder="Max Rate" />
                </div>
              </div>
              <Title bottomSpace={3} className={"fs-5"}>Would You Consider Your Business Specialist for Any of the Following Wedding Types?</Title>
              <div className="d-flex flex-wrap gap-2 mb-3">
                {ethnics?.map((value, index) => (
                  <SelectButton key={index} id={`${value?._id}-${index + 1}-weddingType`} label={value?.name} onChange={(e) => setWeddingType(e.target.value)} checked={weddingType === value?._id} value={value?._id} name="weddingType" />
                ))}
              </div>
            </div>
          </div>

          {/* Contact Details */}
          <Title className={"text-dark fs-3"}>Contact Details</Title>
          <div className="row gy-3">
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setContact(e.target.value)} value={contact} placeholder="Contact Person" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setPhone(e.target.value)} value={phone} placeholder="Phone" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setEmail(e.target.value)} value={email} placeholder="Email" />
            </div>
            <div className="col-md-4">
              <FloatingInput onChange={(e) => setMobile(e.target.value)} value={mobile} placeholder="Mobile" />
            </div>
            <div className="col-md-1 align-self-center">
              <DynamicCheckbox variant="pink" size="large" onChange={(e) => setWhatsapp(e.target.checked ? mobile : '')}>WhatsApp</DynamicCheckbox>
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setWebsite(e.target.value)} value={website} placeholder="Website" />
            </div>
          </div>

          {/* Location Details */}
          <Title className={"text-dark fs-3"} topSpace={3} bottomSpace={3}>Location Details</Title>
          <div className="row gy-3">
            <div className="col-md-6">
              <Title bottomSpace={3} className={"fs-5"}>Location</Title>
              <GooglePlacesAutocomplete
                apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                selectProps={{
                  value: location,
                  onChange: setLocation,
                  placeholder: address || "Enter your location",
                }}
              />
              <FloatingTextArea topSpace={3} height="160" onChange={(e) => setAddress(e.target.value)} value={address} placeholder="Address" />
            </div>
            <div className="col-md-6">
              <DynamicSelect placeholder="Country" bottomSpace={3} value={country} onChange={(e) => setCountry(e.target.value)} />
              <FloatingInput onChange={(e) => setCity(e.target.value)} placeholder="City" value={city} bottomSpace={3} />
              <FloatingInput onChange={(e) => setPostCode(e.target.value)} placeholder="Postcode" value={postCode} />
            </div>
          </div>

          {/* Social Network */}
          <Title className={"text-dark fs-3"} bottomSpace={3} topSpace={3}>Social Network</Title>
          <div className="row gy-3">
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setFacebook(e.target.value)} value={facebook} placeholder="Facebook" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setX(e.target.value)} value={x} placeholder="X" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setLinkedin(e.target.value)} value={linkedin} placeholder="Linkedin" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setInstagram(e.target.value)} value={instagram} placeholder="Instagram" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setYoutube(e.target.value)} value={youtube} placeholder="Youtube" />
            </div>
            <div className="col-md-6">
              <FloatingInput onChange={(e) => setPinterest(e.target.value)} value={pinterest} placeholder="Pinterest" />
            </div>
          </div>

          {/* FAQs */}
          <Title className={"text-dark fs-3"} bottomSpace={3} topSpace={3}>FAQs</Title>
          {addFaq.map((faq, index) => (
            <div key={index}>
              <div className="d-flex justify-content-between align-content-center mt-3">
                <p className="font-size-22 pink-color">FAQ {index + 1}</p>
                {index > 0 && (
                  <div className="cursor-pointer pink-color" onClick={() => deleteFaq(index)}><Trash2 size={18} /></div>
                )}
              </div>
              <div className="col-md-12">
                <FloatingInput onChange={(e) => handleChange(index, "question", e.target.value)} value={faq.question} placeholder="FAQ Question" bottomSpace={3} />
              </div>
              <div className="col-md-12">
                <FloatingTextArea topSpace={3} height="160" onChange={(e) => handleChange(index, "answer", e.target.value)} value={faq.answer} placeholder="FAQ Answer" />
              </div>
            </div>
          ))}
          {checkFaq && (
            <p className="font-size-18 purple-color mt-2 cursor-pointer" onClick={handleAddFaq}>Add more FAQs +</p>
          )}

          {/* Discounts */}
          <Title topSpace={3} bottomSpace={3} className={"fs-5"}>Add Special Discounts (Exclusive discount for Weddified Couples)</Title>
          <div className="d-flex flex-wrap gap-2 mb-3">
            {vendorSpecialDiscount.map((value, index) => (
              <SelectButton key={index} id={`${value.value}-${index + 1}-discount`} label={value.value} onChange={(e) => setDiscount(e.target.value)} checked={discount === value.value} value={value.value} name="discount" />
            ))}
          </div>

          {/* Photos & Videos */}
          <Title bottomSpace={3} className={"text-dark fs-3"}>Photos & Videos</Title>
          <div className="mb-3">
            <ImageUpload onChange={(uploadedImages) => setImages(uploadedImages)} />
          </div>

          <Title topSpace={3} bottomSpace={3} className={"fs-5"}>Youtube / Vimeo / Dailymotion Video URL</Title>
          <div className="row">
            <div className="col-md-11">
              <FloatingInput onChange={(e) => setVideoUrl(e.target.value)} value={videoUrl} placeholder="Video URL" />
            </div>
            <div className="col-md-1 align-self-center">
              <Button variant="pink" onClick={addVideo}>Add</Button>
            </div>
          </div>
          <div className="row">
            {links.map((link, index) => {
              const embedUrl = getEmbedUrl(link.url);
              return (
                embedUrl && (
                  <div key={index} className="col-md-4 position-relative">
                    <div className="video-wrapper position-relative mt-4">
                      <X className="position-absolute top-0 end-0 m-2 text-white bg-danger rounded-circle p-1 cursor-pointer" style={{ zIndex: 10 }} onClick={() => removeVideo(index)} />
                      <div className="ratio ratio-16x9">
                        <iframe src={embedUrl} frameBorder="0" allowFullScreen></iframe>
                      </div>
                    </div>
                  </div>
                )
              );
            })}
          </div>

          {/* Preferred Suppliers */}
          <Title topSpace={3} bottomSpace={3} className={"fs-5"}>Preferred Suppliers</Title>
          <FloatingInput onChange={(e) => setSuggestedVendors(e.target.value)} value={suggestedVendors} placeholder="Suggested Vendors (Search Vendors)" />

        </div>
        <div className="d-flex align-items-center justify-content-center gap-3">
          <Button variant="purple-outline" size="small">Cancel</Button>
          <Button variant="pink" size="small" onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </>
  );
};

export default AddVendor;
