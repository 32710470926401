import React from 'react'

const Title = ({children, topSpace = 0, bottomSpace = 0, className}) => {
  const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
  const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);
  return (
    <h2 className={`font-size-28 pink-color dm-font mb-0 mt-${validTopSpace} mb-${validBottomSpace} ${className}`}>{children}</h2>
  )
}

export default Title