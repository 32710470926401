import React from 'react'

const Table = ({className, children}) => {
  return (
    <>
    <div class="table-responsive">
        <table class={`table wf-table table-borderless table-striped ${className}`}>
            {children}
        </table>
    </div>
    </>
  )
}

export default Table