import React, { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import Login from '../pages/Login';
import { useSelector } from 'react-redux';
import { getUserRole, getUserToken, getUsertoken } from '../store/apiSlice';
import Areas from '../pages/Areas';
import Categories from '../pages/Categories';
import Dashboard from '../pages/Dashboard';
import Navbar from './Navbar';
import SideBar from './SideBar';
import Cities from '../pages/Cities';
import Ethnics from '../pages/Ethnics';
import Attributes from '../pages/Attributes';
import Themes from '../pages/Themes';
import Religions from '../pages/Religions';
import AddAdmin from '../pages/AddAdmin';
import Users from '../pages/Users';
import Countries from '../pages/Countries';
import Question from '../pages/Question';
import AddQuestion from '../pages/AddQuestion';
import Suppliers from '../pages/Suppliers';
import AddSupplier from '../pages/AddSupplier';
import Blogs from '../pages/Blogs';
import BlogCategories from '../pages/BlogCategories';
import ReportListing from '../pages/ReportListing';
import ClaimListing from '../pages/ClaimListing';
import Vendors from '../pages/Vendors';
import ContactForm from '../pages/ContactForm';
import CustomerInquiries from '../pages/CustomerInquiries';
import AddBlogs from '../pages/AddBlogs';
import AddVendor from '../pages/AddVendor';
import Admin from '../pages/Admin';

const Layout = () => {
  const authtoken = useSelector(getUserToken);
  const role = useSelector(getUserRole)
  return (
    <>
      <BrowserRouter>
        <Content authtoken={authtoken} role={role} />
      </BrowserRouter>
    </>
  )
}

const Content = ({ authtoken, role }) => {
  const location = useLocation();
  return (
    <>
      {authtoken && (role === "admin" || role === "sub-admin") ? <>
        {location.pathname === "/login" ? <></> : <Navbar />}
        {location.pathname === "/login" ? <></> : <SideBar />}

        <section className={location.pathname == "/login" ? "" : "content-section"}>
          <Routes>
            <Route path="/" exact element={<Dashboard />} />
            <Route path="*" exact element={<Dashboard />} />
            <Route path="/categories" exact element={<Categories />} />
            <Route path="/attributes" exact element={<Attributes />} />
            <Route path="/areas" exact element={<Areas />} />
            <Route path="/cities" exact element={<Cities />} />
            <Route path="/countries" exact element={<Countries />} />
            <Route path="/ethnics" exact element={<Ethnics />} />
            <Route path="/themes" exact element={<Themes />} />
            <Route path="/religions" exact element={<Religions />} />
            <Route path="/blogs" exact element={<Blogs />} />
            <Route path="/add-blog" exact element={<AddBlogs />} />
            <Route path="/edit-blog" exact element={<AddBlogs />} />
            <Route path="/blog-categories" exact element={<BlogCategories />} />
            <Route path="/report-listings" exact element={<ReportListing />} />
            <Route path="/claim-listings" exact element={<ClaimListing />} />
            <Route path="/customer-users" exact element={<Users />} />
            <Route path="/planner-users" exact element={<Users />} />
            <Route path="/supplier-users" exact element={<Users />} />
            <Route path="/contact-form" exact element={<ContactForm />} />
            <Route path="/add-admin" exact element={<AddAdmin />} />
            <Route path="/edit-admin" exact element={<AddAdmin />} />
            <Route path="/admins" exact element={<Admin />} />
            <Route path="/customer-inquiries" exact element={<CustomerInquiries />} />
            <Route path="/planners" exact element={<Users />} />
            <Route path="/vendors" exact element={<Vendors />} />
            <Route path="/add-vendor" exact element={<AddVendor />} />
            <Route path="/questions" exact element={<Question />} />
            <Route path="/add-question" exact element={<AddQuestion />} />
            <Route path="/edit-question" exact element={<AddQuestion />} />
            <Route path="/suppliers" exact element={<Suppliers />} />
            <Route path="/add-supplier" exact element={<AddSupplier />} />
          </Routes>
        </section>
      </> : <>
        <Routes>
          <Route path="/" exact element={<Login />} />
          <Route path="*" exact element={<Login />} />
        </Routes>
      </>}
    </>
  );
};

export default Layout