import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extraReducers } from "./apiReducer";

axios.defaults.baseURL = process.env.REACT_APP_APIURL;
const user = JSON.parse(localStorage.getItem("user"))
  ? JSON.parse(localStorage.getItem("user"))
  : null;
axios.defaults.headers.common["Authorization"] = user?.token
  ? `Bearer ${user.token}`
  : "";

const initialState = {
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
  user: user,
};

export const signIn = createAsyncThunk("/auth/login", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/auth/login`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addCountry = createAsyncThunk("/countries/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/countries/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editCountry = createAsyncThunk("/countries/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/countries/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getCountryList = createAsyncThunk("/countries/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/countries/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
})
export const deleteCountry = createAsyncThunk("/countries/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/countries/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addCity = createAsyncThunk("/cities/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/cities/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editCity = createAsyncThunk("/cities/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/cities/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getCitiesList = createAsyncThunk("/cities/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/cities/list`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteCity = createAsyncThunk("/cities/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/cities/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addArea = createAsyncThunk("/areas/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/areas/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editArea = createAsyncThunk("/areas/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/areas/update/${bodyData.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getAreasList = createAsyncThunk("/areas/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/areas/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteArea = createAsyncThunk("/areas/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/areas/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addCategory = createAsyncThunk("/categories/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/categories/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response?.data);
  }
});

export const editCategory = createAsyncThunk("/categories/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/categories/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getCategoryList = createAsyncThunk("/categories/all", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/categories/list`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteCategory = createAsyncThunk("/categories/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/categories/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addEthnics = createAsyncThunk("/ethnics/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/ethnics/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editEthnics = createAsyncThunk("/ethnics/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/ethnics/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getEthnicsList = createAsyncThunk("/ethnics/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/ethnics/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteEthnics = createAsyncThunk("/ethnics/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/ethnics/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addTheme = createAsyncThunk("/themes/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/themes/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editTheme = createAsyncThunk("/themes/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/themes/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getThemeList = createAsyncThunk("/themes/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/themes/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteTheme = createAsyncThunk("/themes/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/themes/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addAttribute = createAsyncThunk("/attributes/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/attributes/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editAttribute = createAsyncThunk("/attributes/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/attributes/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getAttributeList = createAsyncThunk("/attributes/all", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/attributes/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteAttribute = createAsyncThunk("/attributes/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/attributes/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addReligion = createAsyncThunk("/religions/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/religions/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editReligion = createAsyncThunk("/religions/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/religions/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getReligionList = createAsyncThunk("/religions/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/religions/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteReligion = createAsyncThunk("/religions/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/religions/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addQuestion = createAsyncThunk("/questions/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/questions/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editQuestion = createAsyncThunk("/questions/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.put(`/questions/update/${bodyData?.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getQuestionList = createAsyncThunk("/questions/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/questions/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteQuestion = createAsyncThunk("/questions/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/questions/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addBlog = createAsyncThunk("/blogs/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/blogs/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getBlogList = createAsyncThunk("/blogs/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/blogs/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editBlog = createAsyncThunk("/blogs/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/blogs/update/${bodyData.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteBlog = createAsyncThunk("/blogs/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/blogs/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getBlogDetail = createAsyncThunk("/blogs/detail", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/blogs/detail/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addBlogCategory = createAsyncThunk("/blog-categories/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/blog-categories/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getBlogCategory = createAsyncThunk("/blog-categories/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/blog-categories/list/`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editBlogCategory = createAsyncThunk("/blog-categories/update", async (bodyData, { rejectWithValue }) => {
  try {
    const data = Object.fromEntries(bodyData)
    const response = await axios.put(`/blog-categories/update/${data.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteBlogCategory = createAsyncThunk("/blog-categories/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/blog-categories/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const addVendor = createAsyncThunk(
  "/vendors/create",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/vendors/create`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getVendorList = createAsyncThunk(
  "/vendors/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/vendors/all/`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editVendor = createAsyncThunk("/vendors/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/vendors/update/${bodyData.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteVendor = createAsyncThunk(
  "/vendors/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/vendors/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addContactQueries = createAsyncThunk(
  "/contacts/create",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/contacts/create`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getContactQueriesList = createAsyncThunk(
  "/contacts/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/contacts/all/`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editContactQueries = createAsyncThunk("/contacts/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/contacts/update/${bodyData.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteContactQueries = createAsyncThunk(
  "/contacts/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/contacts/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addCustomerInquiries = createAsyncThunk(
  "/customers/create",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/customers/create`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getCustomerInquiriesList = createAsyncThunk(
  "/customers/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/customers/all/`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editCustomerInquiries = createAsyncThunk("/customers/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/customers/update/${bodyData.id}`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteCustomerInquiries = createAsyncThunk(
  "/customers/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/customers/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addClaimListing = createAsyncThunk(
  "/listings/create",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/listings/create`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getClaimListingList = createAsyncThunk(
  "/listings/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/listings/all/`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editClaimListing = createAsyncThunk(
  "/listings/update",
  async ({ bodyData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/listings/update/${id}`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteClaimListing = createAsyncThunk(
  "/listings/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/listings/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addReportListing = createAsyncThunk(
  "/report/create",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/report/create`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getReportListingList = createAsyncThunk(
  "/report/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/report/all/`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editReportListing = createAsyncThunk(
  "/report/update",
  async ({ bodyData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/report/update/${id}`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteReportListing = createAsyncThunk(
  "/report/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/report/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addSuppliers = createAsyncThunk(
  "/store/add",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/store/add`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const editSuppliers = createAsyncThunk(
  "/store/edit",
  async ({ bodyData, id }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/store/edit/${id}`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getSuppliersList = createAsyncThunk(
  "/store/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/store/all`, bodyData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const featureSupplier = createAsyncThunk(
  "/store/feature",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/store/feature/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSupplier = createAsyncThunk(
  "/store/delete",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/store/delete/${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const addAdmin = createAsyncThunk("/admin/create", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/admin/create`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const editAdmin = createAsyncThunk("/admin/update", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/admin/update`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getAdminList = createAsyncThunk("/admin/list", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.get(`/admin/list`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const deleteAdmin = createAsyncThunk("/admin/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/admin/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const getUserList = createAsyncThunk(
  "/user/all",
  async (bodyData, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/user/all?role=${bodyData}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteUser = createAsyncThunk("/user/delete", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.delete(`/user/delete/${bodyData}`);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});
export const uploadAttachment = createAsyncThunk("/common/upload-image", async (bodyData, { rejectWithValue }) => {
  try {
    const response = await axios.post(`/common/upload-image`, bodyData);
    return response.data;
  } catch (error) {
    return rejectWithValue(error.response.data);
  }
});

const apiSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    token: (state) => {
      var user = JSON.parse(localStorage.getItem("user"));
      if (user) {
        state.user = user?.token;
      }
    },
  },
  extraReducers,
});
export const { token } = apiSlice.actions;
export const getProfile = (state) => state?.users?.user;
export const getUserID = (state) => state?.users?.user?._id;
export const getUserToken = (state) => state?.users?.user?.token;
export const getUserRole = (state) => state?.users?.user?.role;

export default apiSlice.reducer;
