import React from 'react';

const generateIdOrName = (label) => {
    return label ? label.toLowerCase().replace(/\s+/g, '-') : `input-${Math.random().toString(36).substr(2, 9)}`;
}

const DynamicSelect = ({
  value,
  onChange,
  id,
  name,
  options = [],             
  optionValue = "value",     
  optionLabel = "label",    
  placeholder = "Select an option", 
  topSpace = 0,              
  bottomSpace = 0,           
  required = false,         
  className = ""
}) => {

    const autoGeneratedId = id || generateIdOrName(placeholder);
    const autoGeneratedName = name || generateIdOrName(placeholder);

  const validTopSpace = Math.min(Math.max(topSpace, 0), 5);
  const validBottomSpace = Math.min(Math.max(bottomSpace, 0), 5);

  return (
    <div className={`mb-${validBottomSpace} mt-${validTopSpace}`}>
      <select className={`form-select wf-select ${className}`} aria-label={placeholder} value={value} onChange={onChange} required={required} id={autoGeneratedId} name={autoGeneratedName}>
        <option value="" disabled selected>{placeholder}</option>
        {options?.map((option, i) => (
          <option key={i} value={option[optionValue]}>
            {option[optionLabel]}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DynamicSelect;
